import * as React from "react"
import "../styles/styles.scss"
const Dynmap = () => (
  <div className="map-container">
    <iframe className="frame" src="http://steeviecraft.hopto.org:8123/">
      <p>Your browser does not support iframes.</p>
    </iframe>
  </div>
)

export default Dynmap
