import * as React from "react"
import "../styles/styles.scss"

//Components
import Header from "../components/header"
import Dynmap from "../components/dynmap"
import Footer from "../components/footer"

const MapPage = () => (
  <div>
    <Header />
    <Dynmap />
    <Footer />
  </div>
)

export default MapPage
